
import { API_BASE_URL, getApiBaseUrl, global_APP } from "./appSettings";
import axios from 'axios';

export default class ContractApi {
  static getContactData = (address) => {
    return new Promise((resolve, reject) => {
      fetch(getApiBaseUrl() + '/mint/' + global_APP.PROJECT_NAME + '/' + (address? `${address}/` : '') +  'contract',
        {
          headers: {
            "X-Project": global_APP.X_PROJECT,
          },
        }
      )
      .then( response => response.json() )
      .then( data => {
        contractData(data).then(
          (data) => {
            resolve(data);
          },
          () => {
            reject();
          }
        );
      })
        .catch(response => {
          reject(response);
        });
    });
  }


  static isWhitelistAccount = (address) => {
    return new Promise((resolve, reject) => {
      try {
        window.grecaptcha.ready(function () {
          window.grecaptcha.execute(global_APP.googleReCaptcha,
            { action: 'submit_isWhitelistAccount' }).then(function (captchaToken) {
              fetch(getApiBaseUrl() + `/whitelist_add/check/${address}`,
              {
                method: 'GET',
              },
              {
                headers: {
                  'ValidationToken': captchaToken,
                  "X-Project": global_APP.X_PROJECT,
                }
              })
                .then(response => response.json())
                .then(data => {
                  wlAccount(data).then(
                    (data) => {
                      resolve(data);
                    },
                    () => {
                      reject();
                    }
                  );

                })
                .catch(response => {
                  reject(response);
                });
            });
        });

      } catch (e) {
        reject({
          message: "Error. Try disabling all browser extensions except metamask"
        });
        console.log("Error. Try disabling all browser extensions except metamask")
      }
    });
  }


  static getSignData = (address, quantity, salesStage) => {
    return new Promise((resolve, reject) => {
      try {
        window.grecaptcha.ready(function () {
          window.grecaptcha.execute(global_APP.googleReCaptcha,
            { action: 'submit_saleMint' }).then(function (captchaToken) {
              fetch(getApiBaseUrl() + '/mint/' + global_APP.PROJECT_NAME  + '/' + address + '/mint/' + salesStage,
                {
                  method: 'POST',
                  body: JSON.stringify({
                    tokenQuantity: +quantity
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                    'ValidationToken': captchaToken,                
                  }
                })
                .then(response => response.json())
                .then(data => {
                  signData(data).then(
                    (data) => {
                      resolve(data);
                    },
                    () => {
                      reject();
                    }
                  )

                })
                .catch(response => {
                  reject(response);
                });
            });
        });
      } catch (e) {
        reject({
          message: 'Error. Try disabling all browser extensions except metamask'
        });
      }
    });
  }

  
}

export function contractData(cd) {
  //debugger
  return new Promise((resolve, reject) => {
    resolve(
      ContractDataFormat(
        cd.contract.address,
        cd.mintName,
        cd.isActive,
        cd.contract.chainId,
        cd.currentSaleStage,
        cd.additionalInfo[0].value,
        cd.additionalInfo[1].value,
        //cd.stages[0].start,

        cd.stages[2].start,
        cd.stages[2].price.wei,
        cd.stages[2].price.eth,
        cd.stages[2].quantity.availableForThisWallet,
        cd.stages[2].quantity.maxPerTrx,
        cd.stages[2].quantity.totalSupply,
        cd.stages[2].quantity.totalLimit,

        cd.stages[3].start,
        cd.stages[3].price.wei,
        cd.stages[3].price.eth,
        cd.stages[3].quantity.availableForThisWallet,
        cd.stages[3].quantity.maxPerTrx,
        cd.stages[3].quantity.totalSupply,
        cd.stages[3].quantity.totalLimit,

        cd.totalLimit,
        cd.totalSupply
      ) //ответ из апи. разложили массив
      );
  });
}

//данные из контракта
export function ContractDataFormat(
  contract,
  mintId,
  mintingIsActive,
  networkId,
  currentSaleStageID,
  openSeaLink,
  looksRareLink,
  //openSaleTime,
  wlOpenTime,
  wlPriceWei,
  wLPriceEth,
  wlAvailableForThisWallet,
  wlMaxPerTrx,
  wlTotalSupply,
  wlTotalLimit,
  puplicOpenTime,
  puplicPriceWei,
  puplicPriceEth,
  puplicAvailableForThisWallet,
  puplicMaxPerTrx,
  puplicTotalSupply,
  puplicTotalLimit,
  totalLimit,
  totalSupply

) {
  //debugger
  return {
    contract: contract || null,
    mintId: mintId !== undefined ? mintId.toString() : null,
    mintingIsActive: mintingIsActive === true,
    networkId: networkId || 0,
    currentSaleStageID: currentSaleStageID,
    openSeaLink: openSeaLink
      ? openSeaLink
      : "https://opensea.io/collection/sneakerheadsofficial",
    looksRareLink: looksRareLink ? looksRareLink : "https://looksrare.org/",
    //openSaleTime: openSaleTime,
    wlOpenTime: wlOpenTime,
    wlPriceWei: wlPriceWei || 0,
    wLPriceEth: wLPriceEth || 0,
    wlAvailableForThisWallet: wlAvailableForThisWallet || 0,
    wlMaxPerTrx: wlMaxPerTrx || 0,
    wlTotalSupply: wlTotalSupply || 0,
    wlTotalLimit: wlTotalLimit || 0,
    puplicOpenTime: puplicOpenTime,
    puplicPriceWei: puplicPriceWei || 0,
    puplicPriceEth: puplicPriceEth || 0,
    puplicAvailableForThisWallet: puplicAvailableForThisWallet || 0,
    puplicMaxPerTrx: puplicMaxPerTrx || 0,
    puplicTotalSupply: puplicTotalSupply || 0,
    puplicTotalLimit: puplicTotalLimit || 0,
    totalLimit: totalLimit || 0,
    totalSupply: totalSupply || 0
  };
}


export function wlAccount(wla) {
  return new Promise((resolve, reject) => {
    resolve(WlAccount(wla.exist, wla.maxTokensToBuy));
  });
}

export function WlAccount(isValid, maxTokensToBuy) {
  return {
    isValid: isValid === true,
    maxTokensToBuy: maxTokensToBuy || 0
  };
}

export function signData(sd) {
  return new Promise((resolve, reject) => {
    resolve(SignData(sd.hash, sd.sign, sd.nonce, sd.quantity, sd.maxQuantity, sd.price.wei, sd.price.eth));
  });
}

export function SignData(hash, sign, nonce, quantity, maxQuantity, priceWei, priceEth) {
  return {
    //hash: (hash !== undefined && hash.length > 0) ? ('0x' + hash) : null,
    //sign: (sign !== undefined && sign.length > 0) ? ('0x' + sign) : null,
    hash: hash || null,
    sign: sign || null,
    nonce: nonce || 0,
    quantity: quantity || 0,
    maxQuantity: maxQuantity || 0,
    priceWei: priceWei || 0,
    priceEth: priceEth || 0
  };
}