import React from 'react';
import { global_APP } from '../appSettings';

const ComingSoon = () => {
  return (
    <div>
      <div className="t1">
        Coming soon
      </div>
      <div className="t2">
        Follow us to be the first to know about the new missions of
        the game and the activities of our community.
      </div>
      <div className="t3">
        Be among like-minded people and become one of the first heroes
        who decided to save the Planet.
      </div>
      <div className="m-0 text-center socio2">
        <div className="text-start text-lg-center text-lg-end d-flex justify-content-around w-100">
          <a href={global_APP.TWITTER_LINK} target="_blank" rel="noopener noreferrer">
            <img src="./img/s-3.svg" alt="" />
          </a>
          <a href={global_APP.OPEN_SEA_LINK} target="_blank" className="nonActive" rel="noopener noreferrer">
            <img src="./img/s-1.svg" alt=""/>
          </a>
          <a href={global_APP.DISCORD_LINK} target="_blank" className="nonActive" rel="noopener noreferrer">
            <img src="./img/s-2.svg" alt=""/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
