import React from 'react';
import { global_APP } from '../appSettings';

const SaleCompleteForUser = () => {
  return (
    <div id="saleCompleteForUserBlock">
      <div className="d-flex align-items-center socio mb-4 tw">
        <a href={global_APP.TWITTER_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-3.svg" alt="" /></a>
        <div>
          <div className="t4 text-uppercase fw-bold">Created by</div>
          <div>@heroes_of_antares</div>
        </div>
      </div>
      <div className="input-mint white text-uppercase fw-bold mb-4 text-center p-3">
        You have bought all the number of tokens available to you
      </div>
      <div className="t4 text-uppercase mb-2">
        Buy on Open Sea or LooksRare!
      </div>
      <div className="m-0 mb-4 text-center socio2">
        <div className="text-start text-lg-center text-lg-end d-flex justify-content-start w-100">
          <a href={global_APP.OPEN_SEA_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-1.svg" alt="" /></a>
          <a href={global_APP.LOOKS_RARE_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-4.svg" alt="" /></a>
        </div>
      </div>
      <div className="t4 text-uppercase mb-2">
        About collection
      </div>
      <div className="t5 mb-2">
        Heroes of Antares is an NFT collection of 5000 on the ethereum blockchain.
      </div>
    </div>
  );
};

export default SaleCompleteForUser;
