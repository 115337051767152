import React from 'react';

const ErrorBlock = ({ errorBlockText }) => {

  return (
    <div id="errorBlock" >
      <div className="t1">
        error
      </div>
      <div className="t3">
        {errorBlockText}
      </div>
      {/* <a href="" className="btn-mint">Back to the Home</a> */}
    </div>
  );
};

export default ErrorBlock;
