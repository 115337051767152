import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { API_BASE_URL, global_APP } from '../appSettings';

const WhitelistCheck = ({ setCheckWhitelist, setAddWhitelist }) => {

  const [token, setToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isError, setIsError] = useState(null);
  const [notWitelist, setNotWitelist] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha('register');
      setToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const ErrorMessagesSchema = Yup.object().shape({
    address: Yup.string()
      .required('required field'),
  });

  const OnSumbit = async (values) => {
    setIsError(false);
    setNotWitelist(false);
    setSuccess(false);
    try {
      if (!executeRecaptcha) {
        return;
      }
      const result = await executeRecaptcha('register');
      setToken(result);

      await axios.get(
        API_BASE_URL + `/whitelist_add/check/${values.address}`,
        {
          headers: {
            "X-Project": global_APP.X_PROJECT,
            'ValidationToken': token,
          },
        })
        .then(result => {
          const dataAnswer = result.data;
          if (result.status === 200 && !dataAnswer.exist) {
            setNotWitelist(true);
          }
          if (result.status === 200 && dataAnswer.exist) {
            setSuccess(true);
          }
        })
        .catch(error => {
          if (error.response) {
            const dataError = error.response.data;
            if (error.response.status === 400 && !dataError.exist) {
              setIsError(true);
            }
          }
        })
        ;
    } catch (error) {
      console.log(error.name)
    }
  }

  return (
    <div>
      <div className="t1 d-flex align-items-center">
        <img src="./img/left.svg" alt="" className="me-3" onClick={() => setCheckWhitelist(false)} />
        <div className="text-decoration-none">Check whitelist</div>
      </div>
      <Formik
        initialValues={{ address: '' }}
        validationSchema={ErrorMessagesSchema}
        onSubmit={(values) => {
          OnSumbit(values);
        }}>
        {({
          values,
          setFieldValue,
          errors,
          touched
        }) => (
          <Form>
            <div className="t4 mb-0 text-uppercase mb-2 mt-4">
              Wallet address
            </div>
            <div className={`input-mint${isError || notWitelist || (errors.address && touched.address) ? ' error' : ''}${success ? ' success' : ''}`}>
              <Field
                type="text"
                placeholder="Wallet address"
                value={values.address}
                name="address"
                onChange={e => { setFieldValue('address', e.target.value); setIsError(false); setNotWitelist(false) }}
              />
              <button type="submit" className="d-none d-lg-block" >Check</button>
            </div>
            {notWitelist ?
              <div class="t4 mb-3 text-uppercase mb-2">
                Unfortunately, you are not in the whitelist.
              </div>
              : ''}
            {isError || (errors.address && touched.address) ?
              <div class="t4 text-danger">{isError ? 'Wrong/invalid wallet address' : errors.address} </div>
              : ''}
            {success ?
              <div class="t4 text-uppercase mb-2 mt-2">Congratulations, you are in the whitelist.</div>
              : ''}
            <button type="submit" className="btn-mint my-2 w-100 d-lg-none">Check</button>
            {notWitelist ?
              <div className="btn-mint mb-3"
                onClick={() => { setAddWhitelist(true); setCheckWhitelist(false); }}>
                Add to whitelist
              </div>
              : ''}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WhitelistCheck;
