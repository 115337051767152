import React from 'react';

const MetaMaskInstalled = ({ children }) => {
  return (
    <div id="metaMaskInstalledBlock">
      {children}
    </div>
  );
};

export default MetaMaskInstalled;
