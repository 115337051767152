import React from 'react';
import ComponentNFT from './ComponentNFT';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { global_APP } from './contract/appSettings';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon, sepolia } from 'wagmi/chains'

// import {
//   EthereumClient,
//   walletConnectProvider,
// } from "@web3modal/ethereum";

// import { Web3Modal } from "@web3modal/react";
// import { configureChains, createClient, WagmiConfig } from "wagmi";
// import { arbitrum, mainnet, polygon } from "wagmi/chains";
// import { w3mConnectors } from '@web3modal/ethereum/dist/_types';

//const chains = [arbitrum, mainnet, polygon];


// Wagmi client
// const { provider } = configureChains(chains, [
//   walletConnectProvider({ projectId: "7cbb9279d995e8a0fcd29cb480541e4d" }),
// ]);
// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({
//     projectId: "7cbb9279d995e8a0fcd29cb480541e4d",
//     version: "1", // or "2"
//     appName: "web3Modal",
//     chains,
//   }),
//   provider,
// });

// const ethereumClient = new EthereumClient(wagmiClient, chains);


const chains = [arbitrum, mainnet, polygon, sepolia]
const projectId = '186bc348ba39073b2a2c425b4bd1571e'
const { provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
  webSocketProvider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)

const App = () => {
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <GoogleReCaptchaProvider reCaptchaKey={global_APP.googleReCaptcha}>
          <ComponentNFT />
        </GoogleReCaptchaProvider>
      </WagmiConfig>
      <Web3Modal
        projectId="7cbb9279d995e8a0fcd29cb480541e4d"
        ethereumClient={ethereumClient}
        themeVariables={{
          '--w3m-text-medium-regular-font-family': 'Zen Dots',
          '--w3m-text-medium-regular-size' : '20px',
          '--w3m-text-medium-regular-weight': '400px',
          '--w3m-accent-color': '#83f31d',
          '--w3m-accent-fill-color': '#000',
          '--motion-translateY': '130px'
          
        }}
      />
    </>
  )
}

export default App;