export const ENV_PR = true; //переключатель прод тест, если true то прод если false то тест

export const API_BASE_URL = ENV_PR ? 'https://heroesofantares.com' : 'https://test.heroesofantares.com';

export const global_APP = {

  DISCORD_LINK: 'https://discord.gg/K5VFADMyep',
  TWITTER_LINK: 'https://twitter.com/Maluscreations',
  OPEN_SEA_LINK: 'https://opensea.io/',
  LOOKS_RARE_LINK: 'https://looksrare.org',
  X_PROJECT: 'HOA',
  PROJECT_NAME: ENV_PR ? 'HOA_PROD' : 'HOA_TEST',
  googleReCaptcha: ENV_PR ? '6LdqvSIkAAAAALkG2AChcZZ0oVM-097Anepk55FH' : '6LdLuyIkAAAAAM1z8rSVM79p4nAUnueEvxKVYGx3',
  COUNT_DOWN_TIMER: new Date(),
  PUBLIC_COUNT_DOWN_TIMER: new Date()
}

export function getApiBaseUrl() {
  return API_BASE_URL;
}

export const SALES_STAGE = {
  PRESALE: 'PRESALE',
  COMMON: 'COMMON'
};


export const ABI = [
  { "inputs": [], "name": "ApprovalCallerNotOwnerNorApproved", "type": "error" },
  { "inputs": [], "name": "ApprovalQueryForNonexistentToken", "type": "error" },
  { "inputs": [], "name": "BalanceQueryForZeroAddress", "type": "error" },
  { "inputs": [], "name": "HoA__InvalidInput", "type": "error" },
  { "inputs": [], "name": "HoA__IsNotATokenOwner", "type": "error" },
  { "inputs": [], "name": "HoA__NothingToWithdraw", "type": "error" },
  { "inputs": [], "name": "HoA__ReplicationAssistantNotSet", "type": "error" },
  { "inputs": [], "name": "HoA__SaleAssistantNotSet", "type": "error" },
  { "inputs": [], "name": "HoA__TokenLockedInReplication", "type": "error" },
  { "inputs": [], "name": "HoA__ZeroAddressProhibited", "type": "error" },
  { "inputs": [], "name": "MintERC2309QuantityExceedsLimit", "type": "error" },
  { "inputs": [], "name": "MintToZeroAddress", "type": "error" },
  { "inputs": [], "name": "MintZeroQuantity", "type": "error" },
  { "inputs": [{ "internalType": "address", "name": "operator", "type": "address" }], "name": "OperatorNotAllowed", "type": "error" },
  { "inputs": [], "name": "OwnerQueryForNonexistentToken", "type": "error" },
  { "inputs": [], "name": "OwnershipNotInitializedForExtraData", "type": "error" },
  { "inputs": [], "name": "TransferCallerNotOwnerNorApproved", "type": "error" },
  { "inputs": [], "name": "TransferFromIncorrectOwner", "type": "error" },
  { "inputs": [], "name": "TransferToNonERC721ReceiverImplementer", "type": "error" },
  { "inputs": [], "name": "TransferToZeroAddress", "type": "error" },
  { "inputs": [], "name": "URIQueryForNonexistentToken", "type": "error" },
  {
    "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
    { "indexed": true, "internalType": "address", "name": "approved", "type": "address" },
    { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "Approval", "type": "event"
  },
  {
    "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
    { "indexed": true, "internalType": "address", "name": "operator", "type": "address" }, { "indexed": false, "internalType": "bool", "name": "approved", "type": "bool" }],
    "name": "ApprovalForAll", "type": "event"
  }, { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "string", "name": "baseUri", "type": "string" }], "name": "BaseUriSet", "type": "event" },
  {
    "anonymous": false, "inputs": [{ "indexed": true, "internalType": "uint256", "name": "fromTokenId", "type": "uint256" }, { "indexed": false, "internalType": "uint256", "name": "toTokenId", "type": "uint256" },
    { "indexed": true, "internalType": "address", "name": "from", "type": "address" }, { "indexed": true, "internalType": "address", "name": "to", "type": "address" }], "name": "ConsecutiveTransfer", "type": "event"
  }, {
    "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "previousOwner", "type": "address" }, { "indexed": true, "internalType": "address", "name": "newOwner", "type": "address" }],
    "name": "OwnershipTransferred", "type": "event"
  }, { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "assistant", "type": "address" }], "name": "ReplicationAssistantSet", "type": "event" },
  { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "assistant", "type": "address" }], "name": "SalesAssistantSet", "type": "event" },
  {
    "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "from", "type": "address" }, { "indexed": true, "internalType": "address", "name": "to", "type": "address" },
    { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "Transfer", "type": "event"
  }, {
    "inputs": [], "name": "OPERATOR_FILTER_REGISTRY", "outputs": [{ "internalType": "contract IOperatorFilterRegistry", "name": "", "type": "address" }],
    "stateMutability": "view", "type": "function"
  }, { "inputs": [{ "internalType": "address[]", "name": "recipients", "type": "address[]" }, { "internalType": "uint256[]", "name": "quantities", "type": "uint256[]" }], "name": "airdropMint", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  { "inputs": [{ "internalType": "address", "name": "operator", "type": "address" }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "approve", "outputs": [], "stateMutability": "payable", "type": "function" },
  { "inputs": [{ "internalType": "address", "name": "owner", "type": "address" }], "name": "balanceOf", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  { "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "burn", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, {
    "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }],
    "name": "getApproved", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function"
  }, {
    "inputs": [{ "internalType": "address", "name": "owner", "type": "address" }, { "internalType": "address", "name": "operator", "type": "address" }], "name": "isApprovedForAll", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view", "type": "function"
  }, { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" }, {
    "inputs": [], "name": "owner", "outputs":
      [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function"
  }, {
    "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "ownerOf", "outputs":
      [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function"
  }, { "inputs": [], "name": "renounceOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, {
    "inputs": [{
      "components": [{ "internalType": "bytes", "name": "signature", "type": "bytes" },
      { "internalType": "uint32", "name": "replicationLockTimestamp", "type": "uint32" }], "internalType": "struct ReplicationSignaturePackage", "name": "signaturePackage", "type": "tuple"
    }, { "internalType": "uint256", "name": "parentTokenId1", "type": "uint256" },
    { "internalType": "uint256", "name": "parentTokenId2", "type": "uint256" }], "name": "replicationMint", "outputs": [], "stateMutability": "nonpayable", "type": "function"
  }, {
    "inputs": [{ "internalType": "address", "name": "from", "type": "address" },
    { "internalType": "address", "name": "to", "type": "address" }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "safeTransferFrom", "outputs": [], "stateMutability": "payable", "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "from", "type": "address" }, { "internalType": "address", "name": "to", "type": "address" }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" },
    { "internalType": "bytes", "name": "data", "type": "bytes" }], "name": "safeTransferFrom", "outputs": [], "stateMutability": "payable", "type": "function"
  }, {
    "inputs": [{
      "components": [{ "internalType": "bytes32", "name": "messageHash", "type": "bytes32" }, { "internalType": "bytes", "name": "signature", "type": "bytes" },
      { "internalType": "uint64", "name": "nonce", "type": "uint64" }], "internalType": "struct SaleSignaturePackage", "name": "signaturePackage", "type": "tuple"
    },
    { "internalType": "uint256", "name": "quantity", "type": "uint256" }], "name": "saleMint", "outputs": [], "stateMutability": "payable", "type": "function"},
  { "inputs": [{ "internalType": "address", "name": "operator", "type": "address" }, { "internalType": "bool", "name": "approved", "type": "bool" }], "name": "setApprovalForAll", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  { "inputs": [{ "internalType": "string", "name": "baseUri", "type": "string" }], "name": "setBaseUri", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  { "inputs": [{ "internalType": "contract IReplicationAssistant", "name": "assistant", "type": "address" }], "name": "setReplicationAssistant", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  { "inputs": [{ "internalType": "contract ISalesAssistant", "name": "assistant", "type": "address" }], "name": "setSalesAssistant", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  { "inputs": [{ "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" }], "name": "supportsInterface", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "symbol", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" },
  { "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "tokenURI", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "totalSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }, {
    "inputs": [{ "internalType": "address", "name": "from", "type": "address" },
    { "internalType": "address", "name": "to", "type": "address" }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "transferFrom", "outputs": [], "stateMutability": "payable", "type": "function"
  },
  { "inputs": [{ "internalType": "address", "name": "newOwner", "type": "address" }], "name": "transferOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  { "inputs": [{ "internalType": "address payable", "name": "recipient", "type": "address" }], "name": "withdraw", "outputs": [], "stateMutability": "nonpayable", "type": "function" }];
