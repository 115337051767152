import { ENV_PR, global_APP, SALES_STAGE } from "./appSettings";
import ContractApi from "./contractApiService";

let networkId = 0,
  mintingIsActive = false,
  mainCountDownTimerVisible = true,
  publicCountDownTimerVisible = false,
  openSaleTime = null,
  wlOpenTime,
  wlPriceWei,
  wLPriceEth,
  wlAvailableForThisWallet,
  wlMaxPerTrx,
  wlTotalSupply,
  wlTotalLimit,
  puplicOpenTime,
  puplicPriceWei,
  puplicPriceEth,
  puplicAvailableForThisWallet,
  puplicMaxPerTrx,
  puplicTotalSupply,
  puplicTotalLimit,
  totalLimit,
  totalSupply,
  salesStage = null,
  lockSubmit = false,
  salesOpenForEveryone = false,
  salesOpenForWhitelist = false,
  collectionSize = 0,
  totalTokensSupply = 0,
  totalTokensBoughtUser = 0,
  totalTokensAvailableForUser = 0,
  saleTokenPrice = 0,
  account = {
    address: null,
    isWhitelistAccount: false,
    maxTokensToBuy: 0
  },
  tokenCountForMintSelectLocal = {
    visible: false,
    selected: null,
    options: []
  }


export function initContractData(address, setLoad, setSaleStage,
  selfInstance, setSelfInstance,
  setTime, setTimePublic,
  mainCountDownTimer, setMainCountDownTimer,
  publicCountDownTimer, setPublicCountDownTimer,
  saleBlocks, setSaleBlocks,
  setTokenCountForMintSelect,
  setAvailableForThisWallet, setSaleTokenPrice,
  setAddressContract
) {

  ContractApi.getContactData(address).then(data => {
   
    networkId = data.networkId || 0;
    openSaleTime = data.openSaleTime;

    setSelfInstance({
      ...selfInstance,
      collectionSize: data.totalLimit,
      totalTokensSupply: data.totalSupply
    });
    setSaleStage(data.currentSaleStageID);
    //setSaleStage(-2);
    setAddressContract(data.contract);
    wlOpenTime = data.openWlTime;
    global_APP.COUNT_DOWN_TIMER = wlOpenTime;
    puplicOpenTime = data.puplicOpenTime;
    global_APP.PUBLIC_COUNT_DOWN_TIMER = data.puplicOpenTime;

    wlOpenTime = data.wlOpenTime;
    wlPriceWei = data.wlPriceWei;
    wLPriceEth = data.wLPriceEth;
    wlAvailableForThisWallet = data.wlAvailableForThisWallet;
    wlMaxPerTrx = data.wlMaxPerTrx;
    wlTotalSupply = data.wlTotalSupply;
    wlTotalLimit = data.wlTotalLimit;
    puplicOpenTime = data.puplicOpenTime;
    puplicPriceWei = data.puplicPriceWei;
    puplicPriceEth = data.puplicPriceEth;
    puplicAvailableForThisWallet = data.puplicAvailableForThisWallet;
    puplicMaxPerTrx = data.puplicMaxPerTrx;
    puplicTotalSupply = data.puplicTotalSupply;
    puplicTotalLimit = data.puplicTotalLimit;
    totalLimit = data.totalLimit;
    totalSupply = data.totalSupply;
    //collectionSize = data.collectionSize;
    //totalTokensSupply = data.totalTokensSupply;
    //totalTokensAvailableForUser = totalTokensAvailableForUser;
    lockSubmit = false;
    mintingIsActive = data.mintingIsActive || false;

    if (!mintingIsActive) {

      setMainCountDownTimer({ ...mainCountDownTimer, visible: false })
      mainCountDownTimerVisible = false;

      setSaleBlocks({
        ...saleBlocks,
        saleHasBeenCompletedBlockVisible: true,
        metaMaskInstalledBlockVisible: false,
        connectMetaMaskBlockVisible: false
      });

      return;
    }

    initTimer(wlOpenTime, puplicOpenTime,
      setTime, setTimePublic,
      mainCountDownTimer, setMainCountDownTimer,
      publicCountDownTimer, setPublicCountDownTimer,
    );

    if (data.currentSaleStageID === 0) {
      setSaleTokenPrice(wLPriceEth);
      if (wlAvailableForThisWallet > wlMaxPerTrx) 
        setAvailableForThisWallet(wlMaxPerTrx)
      else
        setAvailableForThisWallet(wlAvailableForThisWallet)
    }
    if (data.currentSaleStageID === 1) {
      setSaleTokenPrice(puplicPriceEth);
      if (puplicAvailableForThisWallet > puplicMaxPerTrx) 
        setAvailableForThisWallet(puplicMaxPerTrx)
      else
        setAvailableForThisWallet(puplicAvailableForThisWallet)
    }
     

    setLoad(false);
  },
    (response) => {
      lockSubmit = false;
      setSaleBlocks({
        ...saleBlocks,
        errorBlockVisible: true,
        errorBlockText: (response && response.message) ? response.message : "Error while executing the query"
      });
    }
  );
}

export async function connectMetaMask(
  address, isConnected, chain,
  saleStage, setLoad,
  availableForThisWallet,
  saleBlocks, setSaleBlocks,
) {

  if (address &&  isConnected && saleStage >= 0) { //инициализируем кошелек и продажи
    setLoad(true);
    account.address = address;
    account.isWhitelistAccount = await ContractApi.isWhitelistAccount(address);
    await initializeDataForMint(
      chain, saleStage,
      availableForThisWallet,
      saleBlocks, setSaleBlocks,
      setLoad)
  }
  else if (saleStage >= 0) {
    setSaleBlocks({
      ...saleBlocks,
      metaMaskInstalledBlockVisible: true,
      mintBlockVisible: false,
      connectMetaMaskBlockVisible: true
    });
  }
}

export function initializeContract(
  address, isConnected,
  setLoad, setSaleStage, 
  selfInstance, setSelfInstance,
  setTime, setTimePublic,
  mainCountDownTimer, setMainCountDownTimer,
  publicCountDownTimer, setPublicCountDownTimer,
  saleBlocks, setSaleBlocks,
  setTokenCountForMintSelect,
  setAvailableForThisWallet, setSaleTokenPrice,
  setAddressContract) {

  //инициализируем контракт и таймеры

    lockSubmit = false;
    
  if (address && isConnected)
    initContractData(address, setLoad, setSaleStage,
      selfInstance, setSelfInstance,
      setTime, setTimePublic,
      mainCountDownTimer, setMainCountDownTimer,
      publicCountDownTimer, setPublicCountDownTimer,
      saleBlocks, setSaleBlocks,
      setTokenCountForMintSelect,
      setAvailableForThisWallet, setSaleTokenPrice,
      setAddressContract
    );
  else
    initContractData('', setLoad, setSaleStage,
      selfInstance, setSelfInstance,
      setTime, setTimePublic,
      mainCountDownTimer, setMainCountDownTimer,
      publicCountDownTimer, setPublicCountDownTimer,
      saleBlocks, setSaleBlocks,
      setTokenCountForMintSelect,
      setAvailableForThisWallet, setSaleTokenPrice,
      setAddressContract
    );
}

async function initializeDataForMint(
  chain, saleStage,
  availableForThisWallet,
  saleBlocks, setSaleBlocks,
  setLoad
) {
  if (lockSubmit) {
    return;
  }

  lockSubmit = true;
  
  if (ENV_PR && chain.id !== 1) {
    lockSubmit = false;
    setSaleBlocks({
      ...saleBlocks,
      metaMaskInstalledBlockVisible: true,
      connectMetaMaskBlockVisible: true,
      errorBlockVisible: true,
      errorBlockText: 'Please choose Ethereum Mainnet network'
    });
    setLoad(false);
    return;
  }

  if (networkId !== chain.id) {
    lockSubmit = false;
    setSaleBlocks({
      ...saleBlocks,
      metaMaskInstalledBlockVisible: true,
      connectMetaMaskBlockVisible: true,
      errorBlockVisible: true,
      errorBlockText: 'Networks match error! (' + networkId + ':' + chain.name + ') \nPlease change network'
    });
    setLoad(false);
    return;
  }

    lockSubmit = false;
    processDataForMintStart(
      saleStage,
      availableForThisWallet,
      saleBlocks, setSaleBlocks,
      setLoad);
}

function processDataForMintStart(
  saleStage,
  availableForThisWallet,
  saleBlocks, setSaleBlocks,
  setLoad) {
    
  if (saleStage === 0 || saleStage === 1) {
    if (lockSubmit) {
      return;
    }
    lockSubmit = true;

      //if (selfInstance.collectionSize === selfInstance.totalTokensSupply) {
      if (totalLimit === totalSupply) {
        setSaleBlocks({
          ...saleBlocks,
          saleHasConcludedBlockVisible: true,
          metaMaskInstalledBlockVisible: false,
          connectMetaMaskBlockVisible: false
        });
        setLoad(false);
        return;
      }
      debugger
      if (saleStage === 0) {      //белая продажа
        
        if (account.isWhitelistAccount.isValid) { //проверка кошелька в белом листе

          lockSubmit = false;

          if (availableForThisWallet > 0) {     //проверка может ли кошелек купить токены больше 0
            setSaleBlocks({
              ...saleBlocks,
              metaMaskInstalledBlockVisible: true,
              mintBlockVisible: true,
              connectMetaMaskBlockVisible: false,
            });
            setLoad(false);
            return;
          } else {
            setSaleBlocks({
              ...saleBlocks,
              saleCompleteForUserComeBackLaterToThePublicSaleBlockVisible: true,
              metaMaskInstalledBlockVisible: false,
              connectMetaMaskBlockVisible: false,
              mintOnlyForWlWalletsBlockVisible: false,
            });
            setLoad(false);
            return;
          }

        } else {
            setSaleBlocks({
              ...saleBlocks,
              mintOnlyForWlWalletsBlockVisible: true,
              metaMaskInstalledBlockVisible: false,
              connectMetaMaskBlockVisible: false,
              saleCompleteForUserComeBackLaterToThePublicSaleBlockVisible: false
            });
            setLoad(false); 
          return;
        }
      } else if (saleStage === 1) {    //публичная продажа

        if (availableForThisWallet > 0) { //проверка может ли кошелек купить токены больше 0
          setSaleBlocks({
            ...saleBlocks,
            metaMaskInstalledBlockVisible: true,
            mintBlockVisible: true,
            connectMetaMaskBlockVisible: false
          });
          setLoad(false);
          return;

        } else {
          setSaleBlocks({
            ...saleBlocks,
            saleCompleteForUserBlockVisible: true,
            metaMaskInstalledBlockVisible: false,
            connectMetaMaskBlockVisible: false
          });
          setLoad(false);
          return;
        }
      }
  } 
  // else {
  //   setSaleBlocks({
  //     ...saleBlocks,
  //     saleNotStartYetBlockVisible: true,
  //     metaMaskInstalledBlockVisible: false,
  //     connectMetaMaskBlockVisible: false
  //   });
  //   setLoad(false);
  //   return;
  // }
}


function f_fillTokenCountForMintSelect(totalTokensSupply, totalTokensAvailableForUser,
  collectionSize,
  setTokenCountForMintSelect) {
  const tokensLeft = collectionSize - totalTokensSupply;
  if (tokensLeft < totalTokensAvailableForUser) {
    totalTokensAvailableForUser = tokensLeft;
  }

  if (totalTokensAvailableForUser > 0) {

    tokenCountForMintSelectLocal.options = [];

    for (var i = 1; i <= totalTokensAvailableForUser; i++) {
      tokenCountForMintSelectLocal.options.push(
        { id: `${i}`, name: `${i}` }
      );
    }

    tokenCountForMintSelectLocal.selected = tokenCountForMintSelectLocal.options[totalTokensAvailableForUser - 1];
    tokenCountForMintSelectLocal.visible = true;

    //setTokenCountForMintSelect(tokenCountForMintSelectLocal);           в этом проект нет селкта
    setTokenCountForMintSelect(totalTokensAvailableForUser);

    return true;
  }

  return false;
}


export function initTimer(wlOpenTime, puplicOpenTime,
  setTime, setTimePublic,
  mainCountDownTimer, setMainCountDownTimer,
  publicCountDownTimer, setPublicCountDownTimer,
) {

  let whitelistSaleStartTime;
  if (typeof wlOpenTime === Number)
    whitelistSaleStartTime = new Date(+wlOpenTime * 1000)
  else
    whitelistSaleStartTime = new Date(wlOpenTime);

  let publicSaleStartTime;
  if (typeof puplicOpenTime === Number)
    publicSaleStartTime = new Date(+puplicOpenTime * 1000)
  else
    publicSaleStartTime = new Date(puplicOpenTime);

  
  const nowDate = new Date();

  const end = new Date(
    whitelistSaleStartTime.getFullYear(), whitelistSaleStartTime.getMonth(),
    whitelistSaleStartTime.getDate(), whitelistSaleStartTime.getHours(),
    whitelistSaleStartTime.getMinutes(), whitelistSaleStartTime.getSeconds()
  );

  const endPublic = new Date(
    publicSaleStartTime.getFullYear(), publicSaleStartTime.getMonth(),
    publicSaleStartTime.getDate(), publicSaleStartTime.getHours(),
    publicSaleStartTime.getMinutes(), publicSaleStartTime.getSeconds()
  );

  const now = new Date(
    nowDate.getUTCFullYear(), nowDate.getUTCMonth(),
    nowDate.getUTCDate(), nowDate.getUTCHours(),
    nowDate.getUTCMinutes(), nowDate.getUTCSeconds()
  );

  const distance = end - now;
  const distancePublic = endPublic - now;

  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;

  setTime([
    Math.floor(distance / _day),
    Math.floor((distance % _day) / _hour),
    Math.floor((distance % _hour) / _minute),
    Math.floor((distance % _minute) / _second)
  ]);

  setTimePublic([
    Math.floor(distancePublic / _day),
    Math.floor((distancePublic % _day) / _hour),
    Math.floor((distancePublic % _hour) / _minute),
    Math.floor((distancePublic % _minute) / _second)
  ]);

  setMainCountDownTimer({
    ...mainCountDownTimer,
    days: whitelistSaleStartTime.getDate(),
    hours: whitelistSaleStartTime.getHours(),
    minutes: whitelistSaleStartTime.getMinutes(),
    seconds: whitelistSaleStartTime.getSeconds()
  })

  setPublicCountDownTimer({
    ...publicCountDownTimer,
    days: publicSaleStartTime.getDate(),
    hours: publicSaleStartTime.getHours(),
    minutes: publicSaleStartTime.getMinutes(),
    seconds: publicSaleStartTime.getSeconds()
  })
  
  if (distance < 0) {
    setMainCountDownTimer({ ...mainCountDownTimer, visible: false });
    mainCountDownTimerVisible = false;
  }

  if (distancePublic < 0) {
    setPublicCountDownTimer({ ...publicCountDownTimer, visible: false });
    publicCountDownTimerVisible = false;
  }

}


export const tick = (d, h, m, s, setTime, setEndTimer) => {

  if (d === 0 && h === 0 && m === 0 && s === 0) {
    setEndTimer(true);
  } else if (h === 0 && m === 0 && s === 0) {
    setTime([d - 1, 23, 59, 59]);
  } else if (m === 0 && s === 0) {
    setTime([d, h - 1, 59, 59]);
  } else if (s === 0) {
    setTime([d, h, m - 1, 59]);
  } else {
    setTime([d, h, m, s - 1]);
  }

};