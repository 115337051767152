import React from 'react';

const WhitelistFirst = ({setAddWhitelist, setCheckWhitelist}) => {
  return (
      <div>
        <div className="t1">
          whitelist
        </div>
        <div className="t4 mb-4">
          and become a Hero who will save the planet
        </div>
        <div className="btn-mint mb-3"
          onClick={()=> setAddWhitelist(true)}>Add to whitelist</div>
        <div className="btn-mint"
          onClick={()=> setCheckWhitelist(true)}>Check whitelist</div>
      </div>
  );
};

export default WhitelistFirst;
