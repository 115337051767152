import React from 'react';


const ConnectMetaMask = ({ children }) => {
  return (
    <div id="connectMetaMaskBlock">
        <div className="d-flex align-items-center socio mb-4 tw">
          <a href="https://twitter.com/heroesofantares" target="_blank" rel="noopener noreferrer"><img src="./img/s-3.svg" alt="" /></a>
          <div>
            <div className="t4 text-uppercase fw-bold">Created by</div>
            <div>@heroes_of_antares</div>
          </div>
        </div>
        <div>
          <div className="input-mint white mb-4">
            <div className="w-100 d-flex align-items-center">
              <div className="px-2"><img src="./img/MetaMask.svg" alt="" /></div>
              <div>MetaMask</div>
            </div>
            {children}
          </div>
        </div>
        <div className="t4 text-uppercase mb-2">
          About collection
        </div>
        <div className="t5 mb-2">
          Heroes of Antares is an NFT collection of 5000 on the ethereum blockchain.
        </div>
    </div>
  );
};

export default ConnectMetaMask;
