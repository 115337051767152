import React, { useEffect, useMemo, useState } from 'react';
import { connectMetaMask, initializeContract } from './contract/initContract';
import ConnectMetaMask from "./contract/blocs/ConnectMetaMask";
import ErrorBlock from "./contract/blocs/ErrorBlock";
import MetaMaskInstalled from "./contract/blocs/MetaMaskInstalled";
import SaleNotStartYet from "./contract/blocs/SaleNotStartYet";
import Mint from "./contract/blocs/Mint";
import MintOnlyForWlWalletsBlockVisible from "./contract/blocs/MintOnlyForWlWalletsBlockVisible";
import PublicSaleNotStartYet from "./contract/blocs/PublicSaleNotStartYet";
import SaleCompleteForUser from "./contract/blocs/SaleCompleteForUser";
import SaleCompleteForUserBlockComeBackLaterToThePublicSale from "./contract/blocs/SaleCompleteForUserBlockComeBackLaterToThePublicSale";
import SaleHasBeenCompleted from "./contract/blocs/SaleHasBeenCompleted";
import SaleHasConcluded from "./contract/blocs/SaleHasConcluded";
import ThanksPurchase from "./contract/blocs/ThanksPurchase";
import WhitelistFirst from './contract/blocs/WhitelistFirst';
import WhitelistAdd from './contract/blocs/WhitelistAdd';
import WhitelistCheck from './contract/blocs/WhitelistCheck';
import ComingSoon from './contract/blocs/ComingSoon';

import { useAccount } from 'wagmi'
import { getNetwork } from '@wagmi/core'
import { Web3Button } from '@web3modal/react';
import { useWeb3ModalTheme } from "@web3modal/react";


const ComponentNFT = () => {

  const { address, isConnected } = useAccount();
  const { chain, chains } = getNetwork();
  const { theme, setTheme } = useWeb3ModalTheme();

  const [addWhitelist, setAddWhitelist] = useState(false);
  const [checkWhitelist, setCheckWhitelist] = useState(false);


  const [load, setLoad] = useState(true);
  const [saleStage, setSaleStage] = useState(-50);

  const [[d, h, m, s], setTime] = useState([0, 0, 0, 0]);
  const [endTimer, setEndTimer] = useState(false);

  const [[d_puplic, h_puplic, m_puplic, s_puplic], setTimePublic] = useState([0, 0, 0, 0]);
  const [endTimerPublic, setEndTimerPublic] = useState(false);

  const [accountsChanged, setAccountsChanged] = useState(0);
  const [addressContract, setAddressContract] = useState('');
  const [saleTokenPrice, setSaleTokenPrice] = useState(0);
  const [availableForThisWallet, setAvailableForThisWallet] = useState(0);
  const [tokenCountForMintSelect, setTokenCountForMintSelect] = useState({
    visible: false,
    selected: null,
    options: []
  });
  const [saleBlocks, setSaleBlocks] = useState({
    connectMetaMaskBlockVisible: false,
    errorBlockVisible: false,
    errorBlockText: '',
    metaMaskInstalledBlockVisible: false,
    metaMaskNotInstalledBlockVisible: false,
    mintBlockVisible: false,
    mintOnlyForWlWalletsBlockVisible: false,
    publicSaleNotStartYetBlockVisible: false,
    saleCompleteForUserBlockVisible: false,
    saleCompleteForUserComeBackLaterToThePublicSaleBlockVisible: false,
    saleHasConcludedBlockVisible: false,
    saleHasBeenCompletedBlockVisible: false,
    saleNotStartYetBlockVisible: false,
    thanksPurchaseBlockVisible: false
  });

  const [mainCountDownTimer, setMainCountDownTimer] = useState({
    visible: true,
    timer: null,
    days: '',
    hours: '',
    minutes: '',
    seconds: ''
  });

  const [publicCountDownTimer, setPublicCountDownTimer] = useState({
    visible: false,
    timer: null,
    days: '',
    hours: '',
    minutes: '',
    seconds: ''
  });

  const [selfInstance, setSelfInstance] = useState({
    collectionSize: 0,
    totalTokensSupply: 0,
    lockSubmit: false
  });


  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        
        setSaleBlocks({
          connectMetaMaskBlockVisible: false,
          errorBlockVisible: false,
          errorBlockText: '',
          metaMaskInstalledBlockVisible: false,
          metaMaskNotInstalledBlockVisible: false,
          mintBlockVisible: false,
          mintOnlyForWlWalletsBlockVisible: false,
          publicSaleNotStartYetBlockVisible: false,
          saleCompleteForUserBlockVisible: false,
          saleCompleteForUserComeBackLaterToThePublicSaleBlockVisible: false,
          saleHasConcludedBlockVisible: false,
          saleHasBeenCompletedBlockVisible: false,
          saleNotStartYetBlockVisible: false,
          thanksPurchaseBlockVisible: false
        });
        setLoad(true);
        setAccountsChanged(Math.random());
      });
      window.ethereum.on("accountsChanged", () => {
        debugger
        
        setSaleBlocks({
          connectMetaMaskBlockVisible: false,
          errorBlockVisible: false,
          errorBlockText: '',
          metaMaskInstalledBlockVisible: false,
          metaMaskNotInstalledBlockVisible: false,
          mintBlockVisible: false,
          mintOnlyForWlWalletsBlockVisible: false,
          publicSaleNotStartYetBlockVisible: false,
          saleCompleteForUserBlockVisible: false,
          saleCompleteForUserComeBackLaterToThePublicSaleBlockVisible: false,
          saleHasConcludedBlockVisible: false,
          saleHasBeenCompletedBlockVisible: false,
          saleNotStartYetBlockVisible: false,
          thanksPurchaseBlockVisible: false
        });
        setLoad(true);
        setAccountsChanged(Math.random());
      });
    }
  }, []);


  useEffect(() => {
    initializeContract(
      address, isConnected, 
      setLoad, setSaleStage,
      selfInstance, setSelfInstance,
      setTime, setTimePublic,
      mainCountDownTimer, setMainCountDownTimer,
      publicCountDownTimer, setPublicCountDownTimer,
      saleBlocks, setSaleBlocks,
      setTokenCountForMintSelect,
      setAvailableForThisWallet, setSaleTokenPrice,
      setAddressContract
    );
  }, [accountsChanged, address, endTimer, endTimerPublic]);

  useEffect(() => {
    if (saleStage >= 0)
      connectMetaMask(
        address, isConnected, chain,
        saleStage, setLoad,
        availableForThisWallet,
        saleBlocks, setSaleBlocks,
      );
  }, [address, accountsChanged, saleStage]);

  setTheme({
    themeMode: "dark",
    themeColor: "green",
    themeBackground: "gradient",
  });

  return (
    <>
      {load ?
        <div className='container_loading'>
          <div className='loading-mint'>
            LOADING
          </div>
        </div>
        :
        <>
          {saleBlocks.errorBlockVisible ?
            <ErrorBlock errorBlockText={saleBlocks.errorBlockText} />
            : ''}

          {/* {mainCountDownTimer.visible && saleStage >= 0 ?
            <SaleNotStartYet
              d={d} h={h} m={m} s={s}
              endTimer={endTimer} setEndTimer={setEndTimer} />
            : ''} */}

          {saleStage >= 0 && saleBlocks.metaMaskInstalledBlockVisible ?
            <MetaMaskInstalled>
              {saleBlocks.connectMetaMaskBlockVisible ?
                <ConnectMetaMask>
                  <Web3Button icon='hide' balance='hide' label="Connect" className="walletConect" />
                </ConnectMetaMask>
                : ''}
              {saleBlocks.mintBlockVisible ?
                <Mint totalSupply={selfInstance.totalTokensSupply}
                  collectionSize={selfInstance.collectionSize}
                  availableForThisWallet={availableForThisWallet}
                  setTokenCountForMintSelect={setTokenCountForMintSelect}
                  saleTokenPrice={saleTokenPrice}
                  saleBlocks={saleBlocks}
                  setSaleBlocks={setSaleBlocks}
                  address = {address}
                  addressContract = {addressContract}
                  salesStage = {saleStage}/>
                : ''}
            </MetaMaskInstalled>
            : ''}

          {saleBlocks.mintOnlyForWlWalletsBlockVisible ?
            <MintOnlyForWlWalletsBlockVisible
              d_puplic={d_puplic} h_puplic={h_puplic} m_puplic={m_puplic} s_puplic={s_puplic}
              endTimerPublic={endTimerPublic} setEndTimerPublic={setEndTimerPublic} />
            : ''}
          {saleBlocks.publicSaleNotStartYetBlockVisible ?
            <PublicSaleNotStartYet />
            : ''}
          {saleBlocks.saleCompleteForUserBlockVisible ?
            <SaleCompleteForUser />
            : ''}
          {saleBlocks.saleCompleteForUserComeBackLaterToThePublicSaleBlockVisible ?
            <SaleCompleteForUserBlockComeBackLaterToThePublicSale 
              d_puplic={d_puplic} h_puplic={h_puplic} m_puplic={m_puplic} s_puplic={s_puplic}
              endTimerPublic={endTimerPublic} setEndTimerPublic={setEndTimerPublic} />
            : ''}
          {saleBlocks.saleHasBeenCompletedBlockVisible ?
            <SaleHasBeenCompleted />
            : ''}
          {saleBlocks.saleHasConcludedBlockVisible ?
            <SaleHasConcluded />
            : ''}
          {saleBlocks.saleNotStartYetBlockVisible ?
            <SaleNotStartYet />
            : ''}
          {saleBlocks.thanksPurchaseBlockVisible ?
            <ThanksPurchase />
            : ''}
          {saleStage === -2 ? <ComingSoon /> : ''}
          {saleStage === -1 && !addWhitelist && !checkWhitelist ?
            <WhitelistFirst setAddWhitelist={setAddWhitelist} setCheckWhitelist={setCheckWhitelist} />
            : ''}
          {addWhitelist ?
            <WhitelistAdd setAddWhitelist={setAddWhitelist} />
            : ''}
          {checkWhitelist ?
            <WhitelistCheck setCheckWhitelist={setCheckWhitelist} setAddWhitelist={setAddWhitelist} />
            : ''}
        </>
      }
    </>
  )
}

export default ComponentNFT;