import React, { useEffect, useState } from 'react';
import { tick } from '../initContract';
import { global_APP } from '../appSettings';


const MintOnlyForWlWalletsBlockVisible = ({d_puplic, h_puplic, m_puplic, s_puplic, endTimerPublic, setEndTimerPublic} ) => {  

  const [[d_loc, h_loc, m_loc, s_loc], setTime_loc] = useState([d_puplic, h_puplic, m_puplic, s_puplic]);

  useEffect(() => {
    if (!endTimerPublic)
      setTimeout(tick, 1000, d_loc, h_loc, m_loc, s_loc, setTime_loc, setEndTimerPublic);
  }, [s_loc]);

  return (
    <div id="mintOnlyForWlWalletsBlockVisible">
      <div className="d-flex align-items-center socio mb-4 tw">
        <a href={global_APP.TWITTER_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-3.svg" alt="" /></a>
        <div>
          <div className="t4 text-uppercase fw-bold">Created by</div>
          <div>@heroes_of_antares</div>
        </div>
      </div>
      <div>
        <div className="input-mint white text-uppercase fw-bold mb-4 text-center p-3">
          <div className="p-2 text-center">Your wallet is not whitelisted. Come on the day of the public sale</div>
        </div>
        <div className="t4 text-uppercase mb-2">Please, come back later</div>
        <div className="row">
          <div className="col-3">
            <div className="input-mint white fw-bold mb-4 flex-wrap">
              <div>
                <div className="text-uppercase w-100 text-center">{d_loc.toString()}</div>
                <div className="fw-light t4">Days</div></div>
            </div>
          </div>
          <div className="col-3">
            <div className="input-mint white fw-bold mb-4 flex-wrap">
              <div>
                <div className="text-uppercase w-100 text-center">{h_loc.toString()}</div>
                <div className="fw-light t4">Hours</div></div>
            </div>
          </div>
          <div className="col-3">
            <div className="input-mint white fw-bold mb-4 flex-wrap">
              <div>
                <div className="text-uppercase w-100 text-center">{m_loc.toString()}</div>
                <div className="fw-light t4">Minutes</div></div>
            </div>
          </div>
          <div className="col-3">
            <div className="input-mint white fw-bold mb-4 flex-wrap">
              <div>
                <div className="text-uppercase w-100 text-center">{s_loc.toString()}</div>
                <div className="fw-light t4">Seconds</div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="t4 text-uppercase mb-2">
        Buy on Open Sea or LooksRare!
      </div>
      <div className="m-0 mb-4 text-center socio2">
        <div className="text-start text-lg-center text-lg-end d-flex justify-content-start w-100">
          <a href={global_APP.OPEN_SEA_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-1.svg" alt="" /></a>
          <a href={global_APP.LOOKS_RARE_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-4.svg" alt="" /></a>
        </div>
      </div>
      <div className="t4 text-uppercase mb-2">
        About collection
      </div>
      <div className="t5 mb-2">
        Heroes of Antares is an NFT collection of 5000 on the ethereum blockchain.
      </div>
    </div>
  );
};

export default MintOnlyForWlWalletsBlockVisible;
