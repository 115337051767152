import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { API_BASE_URL, global_APP } from '../appSettings';

const WhitelistAdd = ({ setAddWhitelist }) => {

  const [token, setToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorWallet, setErrorWallet] = useState(null);
  const [errorEmailMessage, setErrorEmailMessage] = useState(null);
  const [errorWalletMessage, setErrorWalletMessage] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha('register');
      setToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const ErrorMessagesSchema = Yup.object().shape({
    checkbox: Yup.boolean()
      .isTrue('some message'),
    email: Yup.string()
    .required('required field'),
    wallet: Yup.string()
    .required('required field')
  });

  const OnSumbit = async (values) => {
    setErrorEmail(false);
    setErrorWallet(false);
    setSuccess(false);
    try {
      if (!executeRecaptcha) {
        return;
      }
      const result = await executeRecaptcha('register');
      setToken(result);
      await axios.post(
        API_BASE_URL + '/whitelist_add/save',
        {
          'email': values.email,
          'wallet': values.wallet
        },
        {
          headers: {
            "X-Project": global_APP.X_PROJECT,
            'ValidationToken': token,
            'Content-Type': 'application/json'
          },
        })
        .then(result => {
          const dataAnswer = result.data;
          if (result.status === 200 && dataAnswer.status === 'OK') {
            setSuccess(true);
          }
        })
        .catch(error => {
          if (error.response) {
            const dataError = error.response.data;
            if (error.response.status === 400 && dataError.emailCheck.status === 'BAD') {
              setErrorEmail(true);
              setErrorEmailMessage(dataError.emailCheck.errorDescription)
            }
            if (error.response.status === 400 && dataError.walletCheck.status === 'BAD') {
              setErrorWallet(true);
              setErrorWalletMessage(dataError.walletCheck.errorDescription)
            }
          }
        })
        ;
    } catch (error) {
      console.log(error.name)
    }
  }

  return (
    <div>
      {!success ?
        <>
          <div className="t1 d-flex align-items-center">
            <img src="./img/left.svg" alt="" className="me-3" onClick={() => setAddWhitelist(false)} />
            <div className="text-decoration-none">Add wallet</div>
          </div>
          <Formik
            initialValues={{ email: '', wallet: '', checkbox: false }}
            validationSchema={ErrorMessagesSchema}
            onSubmit={(values) => {
              OnSumbit(values);
            }}>
            {({
              values,
              setFieldValue,
              errors,
              touched
            }) => (
              <Form>
                <div className="t4 mb-0 text-uppercase mb-2">
                  Share your email to be in touch with you
                </div>
                <div className={`input-mint${(errorEmail) || (errors.email && touched.email) ? ' error' : ''}`}>
                  <Field
                    type="text"
                    placeholder="email"
                    value={values.email}
                    name="email"
                    onChange={e => { setFieldValue('email', e.target.value); setErrorEmail(false); }}
                  />
                </div>
                {errorEmail || (errors.email && touched.email) ?
                  <div className="t4 text-danger">{errorEmailMessage ? errorEmailMessage : errors.email}</div>
                  : ''}
                <div className="d-flex my-4">
                  <div className="me-2">
                    <Field
                      type="checkbox"
                      className="mint-checkbox"
                      name='checkbox'
                    />
                  </div>
                  <div className={`t4 fw-light ${errors.checkbox && touched.checkbox  ? 'errorMessage' : ''}`}>
                    By using this form you agree with the storage and handling of your data by this website in accordance with our Privacy Policy
                  </div>
                </div>
                <div className="t4 mb-0 text-uppercase mb-2">
                  Wallet address
                </div>
                <div className={`input-mint${errorWallet || (errors.wallet && touched.wallet) ? ' error' : ''}`}>
                  <Field
                    type="text"
                    placeholder="Wallet address"
                    value={values.wallet}
                    name="wallet"
                    onChange={e => { setFieldValue('wallet', e.target.value); setErrorWallet(false); }}
                  />
                  <button type="submit" className="d-none d-lg-block">Add</button>
                </div>
                {errorWallet || (errors.wallet && touched.wallet) ?
                  <div className="t4 text-danger">{errorWalletMessage ? errorWalletMessage : errors.wallet}</div>
                  : ''}
                <button type="submit" className="btn-mint my-2 w-100 d-lg-none">Add</button>
              </Form>
            )}
          </Formik>
        </>
        :
        <div >
          <div className="t1 d-flex align-items-center">
            Thank you!
          </div>
          <div className="t4 mb-3 text-uppercase mb-2">
            You are successfully added in the Whitelist
          </div>
          <div className="btn-mint" onClick={()=> setAddWhitelist(false)}>Back Home</div>
        </div>}
    </div>

  );
};

export default WhitelistAdd;
