import React, { useEffect, useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { ABI, global_APP } from '../appSettings';
import ContractApi from '../contractApiService';


const Mint = ({ totalSupply,
  collectionSize,
  availableForThisWallet, setTokenCountForMintSelect,
  saleTokenPrice,
  saleBlocks, setSaleBlocks,
  address, addressContract, salesStage
}) => {

  const [mintLoading, setMintLoading] = useState(false);
  const [buttonClick, setButtonClick] = useState(true);
  const [editNumber, setEditNumber] = useState(1);
  const [hash, setHash] = useState('');
  const [sign, setSign] = useState('');
  const [nonce, setNonce] = useState(0);
  const [priceWei, setPriceWei] = useState(0);
  const [priceEth, setPriceEth] = useState(0);


  const { config, status } = usePrepareContractWrite({
    address: addressContract,
    abi: ABI,
    functionName: "saleMint",
    args: [[hash, sign, nonce], editNumber],
    overrides: {
      // gasLimit: 1000000,
      from: address,
      value: priceWei
    },
  });

  const {
    isError,
    data: mintData,
    write,
  } = useContractWrite(config);

  const { isSuccess: isMinted } = useWaitForTransaction({
    hash: mintData?.hash,
  });

  useEffect(() => {
    if (status === 'success' && buttonClick) {
      write();
      setButtonClick(false);
    }
  }, [status])

  useEffect(() => {
    if (isError) {
      setMintLoading(false);
    }
  }, [isError])

  useEffect(() => {
    if (isMinted) {
      setSaleBlocks({
        ...saleBlocks,
        thanksPurchaseBlockVisible: true,
        metaMaskInstalledBlockVisible: false,
        connectMetaMaskBlockVisible: false
      });
      setMintLoading(false);
    }
  }, [isMinted])


  const onMintClick = async () => {
    setMintLoading(true);
    setButtonClick(true);
    ContractApi.getSignData(
      address, editNumber, salesStage, global_APP.X_PROJECT
    ).then(async signData => {
      setHash(signData.hash);
      setSign(signData.sign);
      setNonce(+signData.nonce);
      setPriceWei(signData.priceWei);
      setPriceEth(signData.priceEth);
    });
  }

  // const selectChange = (event) => {                            в этом прокт нет селкта
  //   setTokenCountForMintSelect((prevQueryParams) => ({
  //     ...prevQueryParams,
  //     selected: {
  //       id: event.target.selectedIndex + 1,
  //       name: event.target.value
  //     }
  //   }))
  // }

  return (
    <div id="mintBlock">
      {mintLoading ?
        <div className='container_loading'>
          <div className='loading-mint'>
            LOADING
          </div>
        </div>
        :
        <>
          <div className="d-flex align-items-center socio mb-4 tw">
            <a href={global_APP.TWITTER_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-3.svg" alt="" /></a>
            <div>
              <div className="t4 text-uppercase fw-bold">Created by</div>
              <div>@heroes_of_antares</div>
            </div>
          </div>
          {isError ?
            <div className="input-mint white text-uppercase fw-bold mb-4 text-center">
              <div className="p-2 text-center">Something went wrong</div>
            </div>
            : ''}
          <div className="t4 text-uppercase mb-2">
            Quantity to mint
          </div>
          <div className="row mb-2">
            <div className="col-12 col-md-5 mb-2">
              <div className="input-mint white h-100">
                <button type="button" className="pm"
                  onClick={() => setEditNumber(editNumber - 1)}
                  disabled={editNumber === 1 ? true : false}
                >-</button>
                <div className="w-100">
                  <input type="number" min="1" max="100" value={editNumber} readOnly className="p-0 text-center" />
                </div>
                <button type="button" className="pm"
                  onClick={() => setEditNumber(editNumber + 1)}
                  disabled={editNumber > availableForThisWallet - 1 ? true : false}
                >+</button>
              </div>
            </div>
            <div className="col-12 col-md-7 mb-2">
              <div className="input-mint white h-100">
                <div className="w-100 text-uppercase ps-2 t3 mb-0 fw-bold">
                  {saleTokenPrice} ETH/piece
                </div>
                <button type="button" className=""
                  onClick={() => onMintClick()}
                  onTouchStart={() => onMintClick()}
                >
                  Mint</button>
              </div>
            </div>
          </div>
          <div className="t5 mb-2">
            {totalSupply}/{collectionSize}
          </div>
          <div className="t4 text-uppercase mb-2">
            Buy on Open Sea or LooksRare!
          </div>
          <div className="m-0 mb-4 text-center socio2">
            <div className="text-start text-lg-center text-lg-end d-flex justify-content-start w-100">
              <a href={global_APP.OPEN_SEA_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-1.svg" alt="" /></a>
              <a href={global_APP.LOOKS_RARE_LINK} target="_blank" rel="noopener noreferrer"><img src="./img/s-4.svg" alt="" /></a>
            </div>
          </div>
          <div className="t4 text-uppercase mb-2">
            About collection
          </div>
          <div className="t5 mb-2">
            Heroes of Antares is an NFT collection of 5000 on the ethereum blockchain.
          </div>
        </>
      }
    </div >
  );
};

export default Mint;
